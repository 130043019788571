import React, { useState, useContext, useEffect } from "react"
import Client from "../Contentful"

const HomeContext = React.createContext()

const HomeProvider = ({ children }) => {
  // State
  const [currentStep, setCurrentStep] = useState(0)
  const [homeData, setHomeData] = useState()
  const [home, setHome] = useState({
    personalProperty: "",
    familyLiability: "",
    guestMedical: "",
    allPerilDeductible: "",
    extendedPremises: "",
    buildingProperty: "",
  })
  // const [home, setHome] = useState()

  // Get Data
  const getData = async () => {
    try {
      let response = await Client.getEntries({
        content_type: "reviewRapidly",
        order: "sys.createdAt",
      })
      // let world = formatWorld(response.items, "landlord")
      // let hello = formatHello(world)
      let coverage = formatData(response.items)
      // setHome(hello)
      setHomeData(coverage)
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    getData()
  }, [])
  // const formatWorld = (items, filter) => {
  //   let data = items
  //     .filter(item => item.fields.temp)
  //     .filter(item => item.fields.temp.includes(filter))

  //   return data
  // }
  // const formatHello = items => {
  //   let data = items.map(item => {
  //     const name = item.fields.name
  //     let temp = {}
  //     return temp
  //   })
  //   console.log(data)
  //   return data
  // }
  const formatData = items => {
    let data = items
      .map(item => {
        let id = item.sys.id
        let temp = { ...item.fields, id }
        return temp
      })
      .filter(item => item.category === "condo")
      .filter(item => item.hasOwnProperty("selection"))

    return data
  }

  const handleHomeChange = e => {
    let name = e.target.name
    let value = e.target.value
    let newData = home

    newData[name] = value

    setHome({ ...newData })
  }

  // Scroll to Top
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    })
  }

  // Form Steps
  const handleNextHomeStep = () => {
    setCurrentStep(prev => prev + 1)
    scrollToTop()
  }
  // previous step in form
  const handlePrevStep = newData => {
    // setVehicles(prev => [...prev, newData])
    setCurrentStep(prev => prev - 1)
    scrollToTop()
  }

  return (
    <HomeContext.Provider
      value={{
        currentStep,
        handlePrevStep,
        handleNextHomeStep,
        handleHomeChange,
        home,
        homeData,
      }}
    >
      {children}
    </HomeContext.Provider>
  )
}

export const useHomeContext = () => {
  return useContext(HomeContext)
}

export { HomeContext, HomeProvider }
