import React from "react"
import HomeCoverage from "./HomeCoverage"
import { useHomeContext } from "../../context/home_context_copy"

const FormTwo = () => {
  const { handlePrevStep, handleNextHomeStep } = useHomeContext()

  return (
    <form className="form" onSubmit={handleNextHomeStep}>
      <div className="form-center">
        <div className="row">
          <h2>Condo</h2>
          <HomeCoverage />
        </div>

        <hr className="line" />
        <div className="btn-container">
          {/* back button */}
          <button
            type="button"
            className="btn back-btn"
            onClick={handlePrevStep}
          >
            Back
          </button>
          {/* next button */}
          <button className="btn next-btn" type="submit">
            Next
          </button>
        </div>
      </div>
    </form>
  )
}

export default FormTwo
